import { render, staticRenderFns } from "./StatisticCardHorizontal.vue?vue&type=template&id=5602feb7"
import script from "./StatisticCardHorizontal.vue?vue&type=script&lang=js"
export * from "./StatisticCardHorizontal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports